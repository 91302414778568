// import logo from "./logo.svg";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Ruby_sPic from "./Ruby_sPic.jpg";
// import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
function App() {
  return (
    <div className="App" dir="rtl">
      <section id="popular" class="module">
        <div class="container">
          <div class="row">
            <div class=" col-sm-offset-3">
              <div
                class="module-header wow fadeInUp animated"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <img
                  src={Ruby_sPic}
                  alt="Ruby_sPic"
                  style={{
                    width: "250px",
                    height: "auto",
                    borderRadius: "50%",
                  }}
                />
                {/* <h2 class="module-title">Popular Dishes</h2>
                <h3 class="module-subtitle">Our most popular menu</h3> */}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">צ'ייסר ליקר</h4>
                    <div class="menu-detail">
                      קלואה | מליבו | פסואה | אפרול | בולס | קמפרי | מידורי
                    </div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪15</h4>
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">צ'ייסר פרימיום</h4>
                    <div class="menu-detail">
                      בחרובקה | דרמבוי | אמרולה | בייליס | ג'ק דבש | ייגר
                    </div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪20</h4>
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">
                      צ'ייסר וודקה / רום לבן / ערק / אוזו{" "}
                    </h4>
                    {/* <div class="menu-detail">Lamb / Wine / Butter</div> */}
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪22</h4>
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">
                      צ'ייסר טקילה / ויסקי / רום מיושן/שחור
                    </h4>
                    <div class="menu-detail">3 ב-₪60</div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪25</h4>
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">
                      הייבול וודקה / רום לבן / ליקר / ערק / אוזו + ערבוב
                    </h4>
                    {/* <div class="menu-detail">Pepper / Chicken / Honey</div> */}
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪30</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">הייבול טקילה / ויסקי + ערבוב</h4>
                    {/* <div class="menu-detail">
                      Mushroom / Veggie / White Sources
                    </div> */}
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪35</h4>
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">בירה מהחבית</h4>
                    {/* <div class="menu-detail">גולדסטאר אנפילטרד</div> */}
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪20</h4>
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">כוס יין</h4>
                    {/* <div class="menu-detail">
                      גוורץ | מרלו | שרדונה | רוזה | קברנה סובניון
                    </div> */}
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪20</h4>
                  </div>
                </div>
              </div>

              {/* <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">סנגרייה חמה</h4>
                    <div class="menu-detail">
                      יין אדום | תבלינים | פירות | קוניאק | קואנטרו | בנדקטין
                    </div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪25</h4>
                    <div class="menu-label">חדש</div>
                  </div>
                </div>
              </div> */}

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">ספיישל חנוכריסמס</h4>
                    <div class="menu-detail">
                      <div>
                        <strong>סופגניה פאשן</strong> - ברבן סופגניות, ריבת
                        תותים וביטר שוקולד.
                      </div>
                      <div>
                        <strong>ביזוזה</strong> - מימוזה ביזון - זוברובקה, סיידר
                        תפוחים צלול וקאווה.
                      </div>
                      <div>
                        <strong>Not a pumpkin pie</strong> - וודקה ציטרון, בטטה,
                        קינמון, אגוז מוסקט וקציפת לימונצ'לו.
                      </div>
                      <div>
                        <strong>שטרמר</strong> - בלוודייר קפה, ביטר
                        אפרסקים ומייפל.
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪45</h4>
                    {/* <div class="menu-label">מומלץ</div> */}
                  </div>
                </div>
              </div>
              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">קוקטייל</h4>
                    <div class="menu-detail">
                      דאקירי | מרגריטה | סאוור | מוסקו/לונדון מיול | נגרוני |
                      פלומה | מרטיני יבש/מלוכלך | דארק & סטורמי | בלודיר | מחייה
                      | קוסמופוליטן | פינה קולדה | קיר רויאל | מימוזה | ועוד..
                    </div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪35</h4>
                    {/* <div class="menu-label">מומלץ</div> */}
                  </div>
                </div>
              </div>

              <div class="menu">
                <div class="row">
                  <div class="col-sm-8">
                    <h4 class="menu-title">כוס שתייה קלה</h4>
                    <div class="menu-detail">
                      פפסי | 7up | חמוציות | טוניק | ג'ינג'ר ביר | קולה-זירו |
                      חמוציות | סודה
                    </div>
                  </div>
                  <div class="col-sm-4 menu-price-detail">
                    <h4 class="menu-price">₪12</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class=" col-sm-offset-3">
              <div
                class="module-header wow fadeInUp animated"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <h5>
                  <strong>
                    חפשו את מתנת הזהב של סנטה קלאוס ברחבי הבר
                    <br /> ואולי תוכלו לזכות שתיה חינם, מרצ'נדייז, ואפילו מיני
                    בקבוקים!
                  </strong>
                </h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class=" col-sm-offset-3">
              <div
                class="module-header wow fadeInUp animated"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                <h5>
                  <strong>המחיר לא כולל שירות ☺</strong>
                </h5>
                <h6>רוצה משהו שאין בתפריט? פנה/י לרובי</h6>
                <br />
                <h6>התשלום במזומן / ביט בלבד</h6>
              </div>
            </div>
          </div>

          {/* <div class="row">
            <div class="col-sm-6 col-sm-offset-3 long-up text-center">
              <a href="menu-simple.html" class="btn btn-custom-1">
                View Menu
              </a>
            </div>
          </div> */}

          <div class="row">
            <div class="col-sm-6 col-sm-offset-3">
              <div class="devider"></div>
            </div>
          </div>
        </div>
      </section>

      {/* <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <h1>Rubi's Menu</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h3>צ'ייסרים</h3>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3>קוקטיילים</h3>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3>אלכוהול</h3>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <h3>בירה ושתייה קלה</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}></Col>
        </Row>
      </Container> */}
    </div>
  );
}

export default App;
